<template>
  <div class="loader-container">
    <overlay-loader :loading="loading" />
    <store-header
      :selectedStore="selectedStore"
      :client="username"
      :total="totalProductsCart"
    />
    <div class="store__cart-container flex-column justify-center">
      <div class="store__cart-info">
        <div class="store__cart-title">
          <span>{{ $t("shop.cart.title") }}</span>
        </div>
        <div class="store__cart-info-table">
          <div class="store__cart-header" v-if="showHeader">
            <div>{{ $t("shop.amount") }}</div>
            <div>{{ $t("shop.product.name") }}</div>
            <div>{{ $t("shop.product.comment") }}</div>
            <div>{{ $t("shop.product.price") }}</div>
            <div>{{ $t("shop.product.options-price") }}</div>
          </div>
          <div class="store__cart">
            <div class="store__cart-body">
              <div
                v-for="item in car"
                :key="item.id"
                class="
                  store__cart-item
                  justify-between justify-center
                  flex-column
                "
                :class="{ selected: selectedItem.id === item.id }"
                @click="changeSelectedItem(item)"
              >
                <div class="store__cart-quantity justify-center">
                  <hcc-button-icon size="sm" @click="deleteItem(item)"
                    ><delete-icon />
                  </hcc-button-icon>
                  <hcc-counter
                    :displayNumber="item.quantity"
                    @add="addItem(item)"
                    @remove="removeItem(item)"
                  />
                </div>
                <div class="store__cart-product">
                  {{ truncateWithDots(item.nombre, 25) }}
                </div>
                <div class="store__cart-product">
                  <hcc-input
                    :value="item.comment"
                    :placeholder="$t('shop.product.comment')"
                    @input="updateComment($event, item.id)"
                  />
                </div>
                <div class="store__cart-price justify-end justify-center">
                  {{ formatNumber(item.precio * item.quantity) }}
                </div>
                <div class="store__cart-price justify-end justify-center">
                  <span>
                    {{ totalOptionsProduct(item) }}
                    <br />
                    <button @click="showOptions">
                      {{ $t("shop.product.options.view-options") }} <eye-icon />
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="store__cart-container-footer">
          <div class="store__cart-footer justify-end justify-center">
            <div class="store__cart-total">Total</div>
            <div class="store__cart-total-price">
              {{ formatNumber(total) }}
            </div>
          </div>
        </div>
        <hcc-button class="cell small-2" size="explaind" @click="continueToBot">
          {{ $t("shop.checkout") }}
        </hcc-button>
      </div>
      <div class="store-detail" v-if="showHeader">
        <div class="store__cart-title">
          <span>{{ $t("shop.detail") }}</span>
        </div>
        <div class="store-detail-description" v-if="selectedItem">
          <div class="store-detail-title">{{ selectedItem.nombre }}</div>
          <div class="store-detail-about">
            {{ itemDescription(selectedItem.id) }}
          </div>
          <div class="store-detail-container">
            <img
              :src="itemImage()"
              alt="item-image"
              class="store-detail-image"
            />
          </div>
        </div>
      </div>
      <hcc-modal
        :title="$t('shop.cart.channels')"
        class="shop"
        :width="400"
        name="shop-cart-channels"
        :adaptive="true"
        :buttons="['cancel']"
      >
        <a
          href="#"
          :key="$index"
          v-for="(channel, $index) in selectedStore.canales"
          @click.prevent="goToChannel(channel)"
        >
          <channel-badge :type="channel.type" class="modal-channels__badge" />
        </a>
      </hcc-modal>
      <hcc-modal
        :title="$t('shop.product.options.title-modal')"
        class="shop"
        :width="500"
        name="group-options"
        :adaptive="true"
        @confirm="editProduct"
      >
        <option-list
          :groupOptions="groupOptions"
          :selectedOptions="selectedItem.groups"
          @onGetOption="getOptions"
        />
      </hcc-modal>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral';
import { mapState, mapActions } from 'vuex';
import ChannelBadge from '@/components/ChannelBadge.vue';
import HccInput from '@/components/shared/HccInput/index.vue';
import HccButton from '@/components/shared/HccButton/index.vue';
import HccButtonIcon from '@/components/shared/HccButtonIcon/index.vue';
import HccCounter from '@/components/shared/HccCounter/index.vue';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import HccModal from '@/components/shared/HccModal/index.vue';
import OptionList from '@/components/shop/OptionList.vue';
import StoreHeader from './StoreHeader.vue';

export default {
  components: {
    HccInput,
    HccModal,
    HccButton,
    HccCounter,
    StoreHeader,
    ChannelBadge,
    OverlayLoader,
    HccButtonIcon,
    OptionList,
  },
  data() {
    return {
      currentItem: null,
      username: null,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      selectedStore: state => state.shop.item,
      products: state => state.product.items,
      currentCar: state => state.shop.currentCar,
      session: state => state.shop.session,
      groupOptions: state => state.product.groupOptionsItems,
    }),
    car() {
      return this.currentCar ? (this.currentCar.car || []) : [];
    },
    totalOptions() {
      const groupTotal = this.car.reduce((totalCar, data) => {
        const totalGroup = data.groups.reduce((totalGroupOptions, group) => {
          const optionTotal = group.opciones.reduce((total, item) => {
            const totalOptions = total + (numeral(item.costoExtra).value() * item.cantidad);
            return totalOptions;
          }, 0);
          return totalGroupOptions + optionTotal;
        }, 0);
        return totalCar + totalGroup;
      }, 0);
      return groupTotal;
    },
    total() {
      const carTotal = this.car.reduce((total, item) => total + (item.quantity * item.precio), 0);
      return carTotal + this.totalOptions;
    },
    showHeader() {
      return this.windowWidth > 959;
    },
    totalProductsCart() {
      return this.car.reduce((total, product) => total + product.quantity, 0);
    },
    selectedItem() {
      return this.currentItem || this.car[0];
    },
  },
  methods: {
    ...mapActions({
      getSelectedStore: 'shop/getById',
      getCar: 'shop/leadPendingCar',
      getProducts: 'product/getItems',
      storeLead: 'shop/newOrUpdateLead',
      removeOldSessions: 'shop/removeSession',
      getGroupOption: 'product/getGroupOption',
    }),
    async editProduct() {
      const tempCar = [...this.car];
      const indexTempCar = tempCar.findIndex(({ id }) => id === this.currentItem.id);
      tempCar[indexTempCar].groups = this.groups;
      await this.storeLead({
        id: this.currentCar ? this.currentCar.id : null,
        userId: this.username,
        storeId: this.selectedStore.id,
        car: tempCar,
      });
    },
    getOptions(groups) {
      this.groups = groups;
    },
    totalOptionsProduct(groupData) {
      const totalGroup = groupData.groups.reduce((totalGroupOptions, groups) => {
        const optionTotal = groups.opciones.reduce((total, item) => {
          const totalOptions = total + (numeral(item.costoExtra).value() * item.cantidad);
          return totalOptions;
        }, 0);
        return totalGroupOptions + optionTotal;
      }, 0);
      return numeral(totalGroup).format('$0,0.00');
    },
    async continueToBot() {
      if (this.username === 'demo') {
        this.$toasted.global.error(this.$t('shop.demo-warning'));
        return;
      }
      if (this.session) {
        this.$modal.show('shop-cart-channels');
        return;
      }
      this.loading = true;
      await this.storeLead({
        id: this.currentCar ? this.currentCar.id : null,
        userId: this.username,
        storeId: this.selectedStore.id,
        car: this.car,
      });
      const url = process.env.VUE_APP_API_BASE_URL;
      const headers = {
        'Content-Type': 'application/json',
      };
      const data = await fetch(`${url}/api/v1/login/manager`, {
        method: 'POST',
        body: JSON.stringify({
          username: 'telegram',
          password: 'holamundo',
        }),
        headers,
      }).then(res => res.json());

      headers.Authorization = `Bearer ${data.token}`;

      await fetch(`${url}/api/v1/chat/newMessage`, {
        method: 'POST',
        body: JSON.stringify({
          type: 'text',
          channelId: this.currentCar.channel || '60b645752376e02c13f3176a',
          username: this.username,
          text: 'CONTINUE_ORDER',
        }),
        headers,
      });
      this.loading = false;
      this.$toasted.global.success(this.$t('shop.congrats'));
    },
    formatNumber(num) {
      return numeral(num).format('$0,0.00');
    },
    async deleteItem({ id: selectedId }) {
      const products = this.car.filter(({ id }) => id !== selectedId);
      await this.storeLead({
        id: this.currentCar ? this.currentCar.id : null,
        userId: this.username,
        storeId: this.selectedStore.id,
        car: products,
      });
      this.currentItem = null;
    },
    async addItem({ id: selectedId }) {
      const tempCar = [...this.car];
      const index = tempCar.findIndex(({ id }) => id === selectedId);
      const product = this.products.find(({ id }) => id === selectedId);
      if (typeof product.stock !== 'undefined') {
        if (tempCar[index].quantity >= product.stock) {
          this.$toasted.global.error(this.$t('shop.stock-warning'));
          return;
        }
      }
      tempCar[index].quantity += 1;
      await this.storeLead({
        id: this.currentCar ? this.currentCar.id : null,
        userId: this.username,
        storeId: this.selectedStore.id,
        car: tempCar,
      });
    },
    async removeItem({ id: selectedId }) {
      const tempCar = [...this.car];
      const index = tempCar.findIndex(({ id }) => id === selectedId);
      tempCar[index].quantity -= 1;
      if (tempCar[index].quantity <= 0) {
        await this.deleteItem(tempCar[index]);
      } else {
        await this.storeLead({
          id: this.currentCar ? this.currentCar.id : null,
          userId: this.username,
          storeId: this.selectedStore.id,
          car: tempCar,
        });
      }
    },
    itemDescription(id) {
      const item = this.products.find(product => product.id === id);

      return item ? item.descripcion : '';
    },
    itemImage() {
      const item = this.products.find(product => product.id === this.selectedItem.id);

      const url = 'http://placehold.it/700x400';

      return item ? item.imageURL : url;
    },
    async changeSelectedItem(item) {
      this.currentItem = item;
      await this.getGroupOption(this.selectedItem.id);
    },
    async showOptions() {
      await this.changeSelectedItem();
      this.$modal.show('group-options');
    },
    async updateComment(event, productId) {
      const tempCar = [...this.car];
      const index = tempCar.findIndex(({ id }) => id === productId);
      tempCar[index].comment = event;
      await this.storeLead({
        id: this.currentCar ? this.currentCar.id : null,
        userId: this.username,
        storeId: this.selectedStore.id,
        car: tempCar,
      });
    },
    goToChannel({ type, username }) {
      const localSession = this.session;
      this.removeOldSessions();
      if (type === 'telegram') {
        window.location.href = `https://t.me/${username}?start=order-${localSession}`;
      } else if (type === 'whatsapp') {
        window.location.href = `https://wa.me/${username}?text=order-${localSession}`;
      }
    },
  },
  async mounted() {
    await this.getSelectedStore(this.$route.params.id);
    this.username = this.$route.params.client;
    this.loading = true;
    await this.getCar({
      user: this.username,
      storeId: this.$route.params.id,
    });
    await this.getProducts(this.$route.params.id);
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/views/_store.scss";
</style>
