<template>
  <div class="counter">
    <button
      class="counter__button-left"
      :class="{ disabled: isDisabled }"
      @click="remove"
      :disabled="isDisabled"
    >
      -
    </button>
    <div class="counter__display">
      {{ displayNumber }}
    </div>
    <button class="counter__button-right" @click="add">+</button>
  </div>
</template>

<script>
export default {
  props: {
    displayNumber: {
      type: Number,
    },
  },
  computed:
  {
    isDisabled() {
      return this.displayNumber === 0;
    },
  },
  methods: {
    add() {
      this.$emit('add');
    },
    remove() {
      this.$emit('remove');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/shared/counter.scss";
</style>
